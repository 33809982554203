import { createApi } from '@reduxjs/toolkit/query/react'
import { gql, GraphQLClient } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { databaseDateToUtc, generateTuples, sha256 } from '../components/Utilities'

// sends persisted query requests (https://github.com/prisma-labs/graphql-request/issues/269)
 const createPersistedQueryFetch = (fetchImpl) => async(info, init) => {
    const request = {info, init};
    const processor = getRequestProcessor(request);
    const requestWithQueryHash = await processor.addHash(request);
    const requestWithoutQuery = processor.removeQuery(requestWithQueryHash);
  
    // send a request without the query
    const res = await fetchImpl(requestWithoutQuery.info, requestWithoutQuery.init);
    const resCopy = res.clone();
    const body = await res.json();
  
    // if the query was not found in the server, send another request with the query
    if (isPersistedQueryNotFoundError(body)) {
        return fetchImpl(requestWithQueryHash.info, requestWithQueryHash.init);
    }
    else {
        res.json = () => Promise.resolve(body);
        return resCopy;
    }
}
  
function getRequestProcessor(request) {
    const method = (request.init?.method ?? 'GET').toUpperCase();
    const requestProcessor = requestProcessorByMethod[method];

    if (!requestProcessor) {
        throw new Error('Unsupported request method: ' + method);
    }

    return requestProcessor;
}
  
const requestProcessorByMethod = {
    GET: {
        removeQuery: request => {
            const [url, params] = splitUrlAndSearchParams(getRequestInfoUrl(request.info))
            params.delete('query')
            return {
                ...request,
                info: requestInfoWithUpdatedUrl(request.info, `${url}?${params.toString()}`)
            }
        },
        addHash: async request => {
            const [url, params] = splitUrlAndSearchParams(getRequestInfoUrl(request.info))
            const query = params.get('query')

            if (!query) {
                throw new Error('GET request must contain a query parameter');
            }
        
            const hash = await sha256(query);
    
            params.append(
                'extensions',
                JSON.stringify({
                    persistedQuery: {
                        version: 1,
                        sha256Hash: hash
                    }
                })
            )
        
            return {
                ...request,
                info: requestInfoWithUpdatedUrl(
                    request.info,
                    `${url}?${params.toString()}`
                )
            }
        }
    },
    POST: {
        removeQuery: request => {
            if (typeof request.init?.body !== 'string') {
                throw new Error('POST request must contain a body');
            }
      
            const body = JSON.parse(request.init.body);
            const {query, ...bodyWithoutQuery} = body;

            return {
                ...request,
                init: {
                    ...request.init,
                    body: JSON.stringify(bodyWithoutQuery),
                }
            }
        },
        addHash: async request => {
            if (typeof request.init?.body !== 'string') {
                throw new Error('POST request must contain a body');
            }
        
            const body = JSON.parse(request.init.body);
        
            if (typeof body.query !== 'string') {
                throw new Error('POST request body must contain a query');
            }
        
            const hash = await sha256(body.query);
    
            return {
                ...request,
                init: {
                    ...request.init,
                    body: JSON.stringify({
                        ...body,
                        extensions: {
                                persistedQuery: {
                                version: 1,
                                sha256Hash: hash
                            }
                        }
                    })
                }
            }
        }
    }
}
  
function requestInfoWithUpdatedUrl(info, url) {
    return typeof info === 'string'
        ? url
        : {
            ...info,
            url
        }
}

function getRequestInfoUrl(info) {
    return typeof info === 'string'
        ? info
        : info.url
}

function splitUrlAndSearchParams(url) {
    const startOfSearchParams = url.indexOf('?');

    return startOfSearchParams === -1
        ? [url, new URLSearchParams()]
        : [url.slice(0, startOfSearchParams), new URLSearchParams(url.slice(startOfSearchParams))]
}
  
function isPersistedQueryNotFoundError(resBody) {
    return (
        resBody.errors &&
        resBody.errors.length > 0 &&
        resBody.errors.find(err => err.message === 'PersistedQueryNotFound') != null
    )
}

const { API, CAMERAS, WEATHER_CATEGORY, DEBUG } = window.conf

const client = new GraphQLClient(
    API,
    {
        fetch: createPersistedQueryFetch(fetch)
    }
)

export const graphqlApi = createApi({
    baseQuery: graphqlRequestBaseQuery({ 
        client,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().data?.token

            if (!!token) {
                headers.set('Authorization', `Bearer ${token}`)
            }

            return headers
        }
    }),
    reducerPath: 'graphqlApi',
    endpoints: (builder) => ({
        // queries data of all cameras in descending order to be processed by generateTuples algorithm
        getDataByCamera: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, graphqlRequestBaseQuery) {

                var subResults = CAMERAS?.map(camera =>
                    graphqlRequestBaseQuery({
                        document: gql`
                        query {
                            getDataByCamera: archivePictures(
                              where: { featureDetailId: { eq: ${camera.featureDetailId} } }
                              order: [{ datum: DESC }]
                            ) {
                              id
                              datum
                              archivePicturesDetails {
                                id
                                kategorie
                                beschreibung
                              }
                            }
                          }
                        `
                    })
                    .then(res => {
                        if (res.error) {
                            throw new Error(res.error.message)
                        } 
                        return res.data?.getDataByCamera;
                    })
                    .catch(error => {
                        console.error(error)
                        throw new Error(error.message)
                    })
                )

                return { data: subResults ? generateTuples(await Promise.all(subResults.filter(subResult => subResult !== undefined))) : [] }
            }
        }),
        // queries tags of all cameras individually and exactly by its current timestamp and not only the global timestamp
        getTagsByCamera: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, graphqlRequestBaseQuery) {

                var { archivePictureByCamera } = _arg
                var subResults = archivePictureByCamera?.map((archivePicture, index) => 
                    archivePicture && graphqlRequestBaseQuery({
                        document: gql`
                        query {
                            getTagsByCamera: archivePicturesDetails(
                              where: {
                                and: [
                                  { archivePictures: 
                                    { featureDetailId: 
                                      { eq: ${CAMERAS[index].featureDetailId} } 
                                    }
                                  }
                                  { archivePictures: 
                                    { datum: 
                                      { eq: "${databaseDateToUtc(archivePicture.datum)}" }
                                    }
                                  }
                                ]
                              }
                            ) {
                              id
                              kategorie
                              beschreibung
                              archivePicturesId
                              weatherArchives {
                                weatherCode
                                temp
                                humidity
                                windSpeed
                              }
                            }
                          }
                        `,
                    })
                    .then(res => {
                        if (res.error) {
                            throw new Error(res.error.message)
                        } 
                        return res.data?.getTagsByCamera;
                    })
                    .catch(error => {
                        console.error(error)
                        throw new Error(error.message)
                    })
                )

                return { data: subResults ? await Promise.all(subResults.filter(subResult => subResult !== undefined)) : [] }
            }
        }),
        // queries tags of single camera between a given timespan
        getTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, graphqlRequestBaseQuery) {

                var { date, index } = _arg
                var result = 
                    graphqlRequestBaseQuery({
                      document: gql`
                      query {
                        getTags: archivePicturesDetails(
                            where: {
                              and: [
                                { archivePictures: 
                                  { featureDetailId: 
                                    { eq: ${CAMERAS[index].featureDetailId} } 
                                  }
                                }
                                { archivePictures:
                                    { datum: 
                                      { gte: "${date}T00:00:00.000Z", lte: "${date}T23:59:59.000Z" }
                                    }
                                }
                                { kategorie: { nin: ["${WEATHER_CATEGORY}"] } }
                              ]
                            }
                          ) {
                              archivePicturesId
                            }
                        }
                      `,
                    })
                    .then(res => {
                        if (res.error) {
                            throw new Error(res.error.message)
                        } 
                        return res.data?.getTags;
                    })
                    .catch(error => {
                        console.error(error)
                        throw new Error(error.message)
                    })

                return { data: await Promise.resolve(result) };
          } 
        }),
        // queries tags of (multiple) given cameras between a timestamp, category and description
        searchTagsByCamera: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, graphqlRequestBaseQuery) {

                var { data } = _arg
                var subResults = data?.cameras?.map(camera => 
                    graphqlRequestBaseQuery({
                      document: gql`
                      query {
                        searchTagsByCamera: archivePicturesDetails(
                            where: {
                              and: [
                                { kategorie: 
                                  { contains: "${data.category}" }
                                }
                                { beschreibung: 
                                  { contains: "${data.description}" }
                                }
                                { archivePictures:
                                  { datum: 
                                    { gte: "${data.startDate}T00:00:00.000Z", lte: "${data.endDate}T23:59:59.000Z" }
                                  }
                                }
                                { archivePictures: 
                                  { featureDetailId: 
                                    { eq: ${camera.featureDetailId} } 
                                  }
                                }
                              ]
                            }
                          ) {
                              id
                              kategorie
                              beschreibung
                              archivePictures {
                                datum
                              }
                              weatherArchives {
                                weatherCode
                                temp
                                humidity
                                windSpeed
                              }
                            }
                        }
                      `,
                    })
                    .then(res => {
                        if (res.error) {
                            throw new Error(res.error.message)
                        } 
                        return {
                            featureDetailId: camera.featureDetailId,
                            data: res.data?.searchTagsByCamera 
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        throw new Error(error.message)
                    })
                )

                return { data: subResults ? await Promise.all(subResults.filter(subResult => subResult !== undefined)) : [] }
            } 
        }),
        constructionSiteDiary: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, graphqlRequestBaseQuery) {
                var { featureDetailId, date } = _arg;
                var result = 
                    graphqlRequestBaseQuery({
                        document: gql`
                            query {
                                constructionSiteDiary(
                                    where: { 
                                        and: [ 
                                            { featureDetailId: { eq: ${featureDetailId} } }
                                            { date: { eq: "${date}" } }
                                        ]
                                    }
                                ) {
                                    weatherSummary
                                    startWork
                                    endWork
                                    maxPeople
                                    vehicles
                                    sunrise
                                    sunset
                                    maxTemp
                                    minTemp
                                    avgTemp
                                    maxHumidity
                                    minHumidity
                                    maxWindSpeed
                                    minWindSpeed
                                    date
                                    internalInfo
                                    weatherForecast
                                    weatherAlerts
                                    weatherSummary
                                    additional
                                    maxPeopleTime
                                }
                            }
                        `,
                    })
                    .then(res => res.data?.constructionSiteDiary)
                    .catch(error => console.error(error))
                          
                return { data: await Promise.resolve(result) }
            }
        }),
        currentWeather: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, graphqlRequestBaseQuery) {

                const featureDetailId = CAMERAS.find(camera => !!camera.featureDetailId)?.featureDetailId

                if (!featureDetailId) {
                    throw new Error("featureDetailId not found")
                }

                const weatherLatestRes = await graphqlRequestBaseQuery({
                    document: gql`
                        query {
                            weatherLatest(where: { featureDetail: { some: { id: { eq: ${featureDetailId} } } } }) {
                                id
                                dateUpdate
                                temp
                                humidity
                                windSpeed
                                sunrise
                                sunset
                                weatherCode
                                forecastCode1
                                forecastMinTemp1
                                forecastMaxTemp1
                                forecastCode2
                                forecastMinTemp2
                                forecastMaxTemp2
                                forecastCode3
                                forecastMinTemp3
                                forecastMaxTemp3
                            }
                        }
                    `
                })
    
                if (weatherLatestRes.error) {
                    throw new Error(weatherLatestRes.error.message)
                }

                const weatherLatest = weatherLatestRes.data?.weatherLatest?.[0]
    
                if (!weatherLatest) {
                    throw new Error("weatherLatest not found")
                }
    
                const weatherLatestAlertsRes = await graphqlRequestBaseQuery({
                    document: gql`
                        query {
                            weatherLatestAlerts(
                                where: {
                                    and: [
                                        { weatherLatestId: { eq: ${weatherLatest.id} } }
                                        { dateUpdate: { eq: "${databaseDateToUtc(weatherLatest.dateUpdate)}" } }
                                    ]
                                }
                            ) {
                                event
                                description
                            }
                        }
                    `
                })
    
                if (weatherLatestAlertsRes.error) {
                    throw new Error(weatherLatestAlertsRes.error.message)
                }

                const weatherLatestAlerts = weatherLatestAlertsRes.data?.weatherLatestAlerts || []

                const currentWeatherRes = {
                    data: {
                        ...weatherLatest,
                        weatherLatestAlerts
                    }
                }

                DEBUG && console.log("currentWeather", currentWeatherRes.data)

                return currentWeatherRes
            }
        }),
        // manipulates tags by array of archivePictureIds
        addTags: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions, graphqlRequestBaseQuery) {

                var { data } = _arg
                var subResults = data?.archivePicturesIds?.map(archivePicturesId => 
                    graphqlRequestBaseQuery({
                        document: gql`
                        mutation {
                            setArchivePicturesDetails(
                                id: ${data.id}
                                archivePicturesId: ${archivePicturesId}
                                kategorie: "${data.category}"
                                beschreibung: "${data.description}"
                            ) {
                                id
                                archivePicturesId
                                kategorie
                                beschreibung
                            }
                        }
                        `,
                    })
                    .then(res => {
                        if (res.error) {
                            throw new Error(res.error.message)
                        } 
                        return res.data?.setArchivePicturesDetails;
                    })
                    .catch(error => {
                        console.error(error)
                        throw new Error(error.message)
                    })
                )
                  
                return { data: subResults ? await Promise.all(subResults.filter(subResult => subResult !== undefined)) : [] }
            }
        }),
        // removes single tag by its id
        removeTag: builder.mutation({
            query: (args) => ({
                document: gql`
                mutation {
                    removeArchivePicturesDetails(
                      id: ${args.id}
                    ) {
                      id
                    }
                  }
                `,
            }),
            transformResponse: (res) => res.removeArchivePicturesDetails
        }),
        addJobToQueue: builder.mutation({
            query: (args) => ({
                document: gql`
                mutation {
                    addJobToQueue(
                        featureDetailId: ${args.featureDetailId}
                        timespanStart: "${args.timespanStart}"
                        timespanEnd: "${args.timespanEnd}"
                        height: ${args.height}
                        width: ${args.width}
                        maxLength: ${args.maxLength}
                        userName: "${args.userName}"
                        userMail: "${args.userMail}"
                        language: "${args.language}"
                    ) {
                        id
                        maxLength
                        timespanStart
                        timespanEnd
                        featureDetailId
                    }
                }
                `,
            }),
            transformResponse: (res) => res.addJobToQueue
        }),
        cancelTimelapseGeneration: builder.mutation({
            query: (args) => ({
                document: gql`
                mutation {
                    cancelTimelapseGeneration(id: "${args.id}")
                }
                `,
            }),
            transformResponse: (res) => res.cancelTimelapseGeneration
        })
    })
})

export const {
    useGetDataByCameraQuery,
    useCurrentWeatherQuery,
    useLazyGetTagsByCameraQuery,
    useLazyGetTagsQuery,
    useLazySearchTagsByCameraQuery,
    useLazyConstructionSiteDiaryQuery,
    useAddTagsMutation,
    useRemoveTagMutation,
    useAddJobToQueueMutation,
    useCancelTimelapseGenerationMutation
} = graphqlApi