import gql from 'graphql-tag';
import { ApolloProvider } from '@apollo/react-hooks';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { split, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { sha256 } from '../components/Utilities'
import { useKeycloak } from '../components/Keycloak';

const { DEBUG, API } = window.conf

if (DEBUG) {
    loadDevMessages()
    loadErrorMessages()
}

export const ApolloProviderWrapper = ({ children }) => { 

    const httpLink = createPersistedQueryLink({ sha256 }).concat(new HttpLink({ uri: API }))
    const { getToken } = useKeycloak()
    const token = getToken()

    const wsLink = new WebSocketLink(
        new SubscriptionClient(API.replace(/^https/, 'wss'), {
            reconnect: true,
            connectionParams: {
                ...(token && {
                    Authorization: `Bearer ${token}`
                })
            }
        })
    )

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query)
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        },
        wsLink,
        httpLink
    )

    const client = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache()
    })

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}

export const jobStatusUpdatedById = gql`
    subscription JobStatusUpdatedById($id: UUID!) {
        jobStatusUpdatedById(id: $id) {
            id
            state
            delay
            creationDate
            timelapseUrl
            userName
            userMail
            language
        }
    }
`;